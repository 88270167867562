<template>
  <div class="loading-spinner">
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :z-index="zIndex"
    >
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="#FF3700"
        indeterminate
      />
      <h1 class="message-color">
        {{ message }}
      </h1>
    </v-overlay>
  </div>
</template>
<script>
  export default {
    name: 'UserQrCodeRedirectLanding',
    data () {
      return {
        loading: false,
        message: '',
        absolute: false,
        opacity: 0.6,
        zIndex: 5,
      };
    },
    mounted () {
      this.storeQrScannedUsersCount();
    },
    methods: {
      async storeQrScannedUsersCount () {
        this.message = 'Redirecting...';
        this.loading = true;
        this.$store.dispatch('qrcodebanners/clearQrCodeBannerId');
        const bannerId = this.$route.query.bannerId;
        const redirectUrl = this.$route.query.redirectUrl;
        const urlType = this.$route.query.urlType;
        if (bannerId === undefined || bannerId === null) {
          this.$router.push({ name: 'QR Code Redirect Failure' });
          return;
        }
        await this.$store.dispatch('qrcodebanners/storeQrScannedUsersCount', {
          bannerId: bannerId,
        }).then(response => {
          if (urlType === 'internal-url') {
            this.$store.dispatch('qrcodebanners/storeQrCodeBannerId', {
              bannerId: bannerId,
            });
            this.$router.push({ name: 'User QR Code Redirect Form' });
          } else {
            window.open(redirectUrl, '_self');
          }
        }).catch(() => {
          this.$router.push({ name: 'QR Code Redirect Failure' });
        });
      },
    },
  };
</script>
<style scoped>
.message-color {
  color: white;
}
.loading-spinner {
  text-align: center;
}
</style>
